<template>
  <t-card class="broadcast-edit d-flex flex-column px-3">
    <div class="d-flex justify-content-between">
      <div>
        <p-tag v-show="broadcast.status === 'DRAFT'" severity="secondary">DRAFT</p-tag>
        <p-tag v-show="broadcast.status === 'PUBLISHED'" severity="primary">PUBLISHED</p-tag>
        <p-tag v-show="broadcast.dirty" severity="warn">UNSAVED</p-tag>
        <p-tag v-show="invalid.length > 0" severity="danger">INVALID</p-tag>
      </div>
      <div>
        <t-button variant="text" class="text-secondary" @click="$emit('clone')">
          <fluency-icon type="duplicate"/>
          Clone
        </t-button>
        <t-button variant="link" class="text-danger" @click="removeBroadcast()">
          <fluency-icon type="delete"/>
          Remove
        </t-button>
      </div>
    </div>
    <div class="d-flex mb-5">
      <div class="w-33">
        <label>Location in Ad</label>
        <div class="mad-lib-container">
          <mad-lib-select :value="broadcast.fieldMapping"
                          :options="makeValuesFriendly(fieldMappingValues)"
                          placeholder="Select a Placement"
                          variant="link"
                          :formatter="$filters.convertFromCapsUnderscore"
                          @input="broadcast.fieldMapping = $event; makeDraft()" />
        </div>
      </div>

      <div class="w-66">
        <label>Valid During</label>
        <div class="mad-lib-container">
          <single-date-picker v-model="broadcast.validFrom" @apply="makeDraft()"></single-date-picker>
          <span class="mx-4">through</span>
          <single-date-picker v-model="broadcast.validTo" opens="left" @apply="makeDraft()"></single-date-picker>
        </div>

      </div>
    </div>
    <div>
      <label>Match Rules</label>
      <div v-for="(rule, index) in broadcast.accountBroadcastMatchers" :key="'match-rule-' + index" class="d-flex-center">
        <span v-if="index === 0" class="mr-3 py-3">Add message when the</span>
        <span v-else class="mr-3 py-3">and the</span>
        <mad-lib-select v-model="rule.matchLevel"
                        class="mr-3"
                        variant="link"
                        placeholder="Select a Match Level"
                        :options="makeValuesFriendly(matchLevelValues)"
                        :formatter="$filters.convertFromCapsUnderscore"
                        @input="makeDraft"></mad-lib-select>
        <mad-lib-select v-model="rule.matchOperator"
                        class="mr-3"
                        variant="link"
                        placeholder="Select a Match Operator"
                        :options="matchOperatorValues"
                        :formatter="$filters.convertFromCapsUnderscore"
                        @input="makeDraft"></mad-lib-select>
        <basic-mad-lib-input class="mr-4" v-model="rule.matchExpression" @input="makeDraft"></basic-mad-lib-input>
        <t-button-inline severity="danger" @click="deleteMatcher(index)">
          <fluency-icon type="delete"/>
        </t-button-inline>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <t-button class="" variant="text" size="sm" @click="addMatcher()">
        <fluency-icon type="add"></fluency-icon>
        Add Match Rule
      </t-button>
    </div>
  </t-card>
</template>

<script>
import MadLibSelect from 'core-ui/components/inputs/madLibSelect'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'
import SingleDatePicker from '~/components/common/dateSelector/components/singleDatePicker.vue'

const accountBroadcastMatcherSkeletonType = 'AccountBroadcastMatcher'

const TAG_REGEX = /\[.*?\]/g

export default {
  name: 'broadcastEdit',
  components: { SingleDatePicker, BasicMadLibInput, MadLibSelect },
  props: {
    value: {
      type: Object
    },
    fieldMappingValues: {
      type: Array,
      default: () => []
    },
    matchLevelValues: {
      type: Array,
      default: () => []
    },
    matchOperatorValues: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      lineLengthValidation: { // check for line length for various items
        HEADLINE_1: 30,
        HEADLINE_2: 30,
        HEADLINE_3: 30,
        DESCRIPTION: 90,
        DESCRIPTION_2: 90,
        PATH_1: 15,
        PATH_2: 15
      }
    }
  },
  computed: {
    creativeMessageContainsFluencyTag () {
      return TAG_REGEX.test(this.value.creativeMessage)
    },
    validLineLengthForAdLocation () {
      if (this.value.creativeMessage && this.broadcast.fieldMapping) {
        // if there is no line length specification do not attempt to validate
        if (!this.lineLengthValidation[this.broadcast.fieldMapping] || this.creativeMessageContainsFluencyTag) {
          return true
        } else {
          return (this.$filters.evaluateLengthAfterInsertion(this.value.creativeMessage) <= this.lineLengthValidation[this.broadcast.fieldMapping])
        }
      } else {
        return false
      }
    },
    broadcast: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    },
    hasMatchers () {
      return this.broadcast.accountBroadcastMatchers &&
        this.broadcast.accountBroadcastMatchers.length > 0
    },
    invalid () {
      const invalid = []
      if (this.broadcast) {
        if (!this.validLineLengthForAdLocation) {
          invalid.push('Invalid line length for location in ad')
        }
        if (!this.broadcast.creativeMessage) {
          invalid.push('Broadcast Message is required')
        }
        if (this.hasMatchers) {
          if (this.broadcast.accountBroadcastMatchers.some(m => !m.matchLevel)) {
            invalid.push('Rule set is missing a match level')
          }
          if (this.broadcast.accountBroadcastMatchers.some(m => !m.matchOperator)) {
            invalid.push('Rule set is missing a match operator')
          }
        }
      }
      return invalid
    }
  },
  methods: {
    makeValuesFriendly (arrayOfValues) {
      return arrayOfValues.map(field => ({
        value: field,
        text: field
      })).map(fieldObj => {
        if (fieldObj.value === 'BODY') fieldObj.text = 'Primary Text'
        if (fieldObj.value === 'TITLE') fieldObj.text = 'Headline'
        if (fieldObj.value === 'SUBTITLE') fieldObj.text = 'Description'
        if (fieldObj.value === 'DESTINATION_URL') fieldObj.text = 'Website Url'
        if (fieldObj.value === 'CAMPAIGN') fieldObj.text = 'Campaign_Name'
        if (fieldObj.value === 'AD_GROUP') fieldObj.text = 'Ad_Group_Name'
        return fieldObj
      })
    },
    async removeBroadcast () {
      this.$setCompat(this.broadcast, 'removed', true)
      this.$emit('removed', this.broadcast) // remove selection since we have started removing broadcasts

      if (this.broadcast.accountBroadcastId > 0) {
        await this.$res.set.broadcasts([this.broadcast])
      }
      const broadcasts = [...this.$store.getters.broadcasts]
      const index = broadcasts.findIndex(b => b.accountBroadcastId === this.broadcast.accountBroadcastId)
      broadcasts.splice(index, 1)
      this.$store.commit('broadcasts', broadcasts)
    },
    addMatcher () {
      const newMatcher = this.$store.getters.newSkeleton(accountBroadcastMatcherSkeletonType)

      newMatcher.accountBroadcastId = this.broadcast.accountBroadcastId
      newMatcher.matchLevel = this.matchLevelValues[0]
      newMatcher.matchOperator = this.matchOperatorValues[0]

      if (!this.broadcast.accountBroadcastMatchers) {
        this.$setCompat(this.broadcast, 'accountBroadcastMatchers', [])
      }
      this.broadcast.accountBroadcastMatchers.push(newMatcher)
      this.$emit('change', this.broadcast)
    },
    deleteMatcher (index) {
      this.broadcast.accountBroadcastMatchers.splice(index, 1)
      this.$emit('change', this.broadcast)
    },
    makeDraft (broadcast) {
      this.$setCompat(this.broadcast, 'dirty', true)
      this.broadcast.status = 'DRAFT'
      this.$emit('change', {
        ...this.broadcast,
        ...(broadcast || {})
      })
    },
    date: function (value) {
      return this.$moment(value).format('MM/DD/YYYY')
    }
  },

  watch: {
    broadcast: {
      handler: function (val, oldVal) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    },
    invalid: {
      handler: function (val) {
        if (this.broadcast.invalid) {
          this.broadcast.invalid = val
        } else {
          this.$setCompat(this.broadcast, 'invalid', val)
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss">
.broadcast-edit .matcher-table .matcher-head > tr > th {
  border: none;
  border-top: 1px solid $fluency-green;
  font-weight: normal;
}

.broadcast-edit .matcher-table td {
  border: none;
}
</style>
