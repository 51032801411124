<template>
  <component :is="card? 't-card' : 'div'" :class="{ 'position-relative w-100 broadcast-container': !card }" :title="card ? 'Broadcast' : undefined">
    <template v-if="!providedAccountBroadcasts && card" #icons>
      <t-button variant="text" @click="cloneActiveMessage()"
                :disabled="!activeBroadcastMessage || buttonBarDisabled">
        <fluency-icon type="duplicate" />
        <div>Clone Message & Rule Sets</div>
      </t-button>
      <t-button variant="text" @click="addNewMessage()"
                :disabled="broadcasts.length > 0 && buttonBarDisabled">
        <fluency-icon type="add"/>
        <div>New Message</div>
      </t-button>
      <t-button variant="text" size="" @click="addBroadcast()" :disabled="buttonBarDisabled">
        <fluency-icon type="add"/>
        <div>New Rule Set</div>
      </t-button>
      <t-button variant="text" :disabled="buttonBarDisabled" @click="openPreview()">
        <fluency-icon type="preview"></fluency-icon>
        <div>Preview and Publish</div>
      </t-button>
      <t-button v-if="isCustomerContext" :variant="dirtyBroadcasts.length > 0 ? 'ghost-warning' : 'ghost-primary'" @click="saveBroadcasts" :disabled="buttonBarDisabled">
        <fluency-icon type="save"/>
        <div>Save</div>
      </t-button>
    </template>
    <div class="d-flex flex-column flex-md-row broadcast-flex-container">
      <div class="d-flex flex-column" style="flex: 0 1 30%"> <!-- sidebar -->
        <p v-if="!hasBroadcasts"><strong>Start By Adding a New Message Above</strong></p>

        <div class="" v-if="hasBroadcasts">
          <label class="mb-2 pb-1">{{contextBroadcastLabel}}</label>
          <p v-if="providedAccountBroadcasts">Select <b-link @click="selectAll()" class="text-primary">All</b-link> / <b-link @click="selectNone()" class="text-primary">None</b-link></p>
          <!-- <t-button  v-if="providedAccountBroadcasts" @click="removeSelectedBroadcasts()" :disabled="selectedBroadcastIds.length === 0">Remove Selected</t-button> -->
          <t-list-group>
            <div style="cursor: pointer">
              <t-list-group-item v-for="(message, broadcastIndex) in uniqueBroadcastMessages"
                                 :key="message"
                                 :active="activeBroadcastMessage === message"
                                 class="py-2 px-2 creative-message d-flex justify-content-between align-items-center"
                                 @change="onBroadcastSelect()"
                                 @click="setActiveBroadcastMessage(message)">
                <span @click.stop v-if="providedAccountBroadcasts"><p-checkbox v-model="selectedBroadcastIds" :inputId="broadcastIndex" :value="broadcastIndex"/></span>
                <span class="text-left w-100 px-2">
                  <span v-if="message">{{message}}</span>
                  <span v-else><em class="">New Broadcast Message</em></span>
                </span>
                <p-tag :severity="getStatusBadgeVariantForMessage(message)">
                  {{getTagForMessage(message)}}
                </p-tag>
              </t-list-group-item>
            </div>
          </t-list-group>
          <small class="form-text text-muted mt-2 pt-1 pb-2" v-if="account && account.name">
            <span v-if="isAccountContext">Creative messaging for
              <strong class="text-success">{{account.name}}</strong>
            </span>
            <span v-else>Global Creative Messaging applied to all accounts</span>
          </small>
        </div>

        <div v-if="$store.getters.isFluencyUser && broadcastMeta.length > 0" class="mt-5 ml-4">
          <t-button @click="specialBroadcastTagsCollapse = !specialBroadcastTagsCollapse" class="p-0" variant="link" ><fluency-icon :class="{'expanded-meta-list' : expandedMetaList}" class="meta-list-icon" type="add" /></t-button> Special Broadcast Tags
          <t-collapse  v-model:visible="specialBroadcastTagsCollapse" class="mt-2" @update:value="expandedMetaList = $event">
            <ul class="list-unstyled ml-5">
              <li v-for="meta in broadcastMeta" :key="meta.attribute" v-click-copy.notooltip="`[${meta.attribute}]`" class="hover-highlight hover-container p-2 d-flex justify-content-between" title="-hide">
                <div class="d-flex">
                  <div v-p-tooltip.top="`${meta.campaigns} matching campaigns`" class="position-relative p-2"><fluency-icon class="pr-3" type="campaign"/><notification-badge color="campaign-bg">{{meta.campaigns}}</notification-badge></div>
                  <div v-p-tooltip.top="`${meta.adGroups} matching ad groups`" class="position-relative p-2"><fluency-icon  class="pr-3" type="adgroup" /><notification-badge color="ad-group-bg">{{meta.adGroups}}</notification-badge></div>
                  <span v-p-tooltip.top="{ value: '<b>Sample Values:</b><br/>' + meta.values.replace(/\|\|/g,'<br/>'), escape: false }" class="p-2">{{meta.attribute}}</span>
                </div>

<!--                <span class="position-relative">-->
<!--                  <fluency-icon type="campaign" />-->
<!--&lt;!&ndash;                  <p-badge severity="secondary">{{meta.campaigns}}</p-badge>&ndash;&gt;-->
<!--                </span>-->
                <div v-p-tooltip.top="'Copy to Clipboard'" class="p-2"><span class="hover-icon"><fluency-icon type="copy" /></span></div>
              </li>
            </ul>
          </t-collapse>

        </div>

        <div class="pt-5">
          <template v-if="isAccountContext && otherContextBroadcastMessages.length > 0">
            <label class="mb-0">Global Broadcast Messages
              <nuxt-link to="/customer/broadcast/" class="white-space-no-wrap">
                <fluency-icon type="edit"/>
                Edit
              </nuxt-link>
            </label>
            <small class="form-text text-muted pb-2">Messages apply across <strong>multiple</strong> accounts.
              Displayed here for <strong>reference only</strong></small>
            <t-list-group>
              <t-list-group-item v-for="message in otherContextBroadcastMessages"
                                 :key="message"
                                 class="mt-1 py-2 creative-message">
                <div>
                  <span>{{message}}</span>
                </div>
              </t-list-group-item>
            </t-list-group>
          </template>

          <template v-else-if="isCustomerContext && $store.getters.isFluencyUser && accountBroadcastDraftsForCustomer.length > 0">
            <label>All Account-Level Draft Broadcasts</label>
            <broadcast-publish-tool :draft-broadcasts="accountBroadcastDraftsForCustomer"
                                    label=" "
                                    @publish="publish($event)"/>
            <small class="form-text text-muted mt-3">
              Use this tool as a convenience to bulk publish account level drafts. <span class="text-danger">Proceed with Caution</span>
            </small>
          </template>
        </div>
      </div>
      <div class="d-flex flex-column d-none d-md-block broadcast-main-content" style="flex: 0 1 65%"> <!-- main -->
        <div v-if="broadcasts.length > 0" class="mb-5">
          <label>Edit Broadcast Message*</label>
          <div class="mad-lib-container">
            <basic-mad-lib-input :value="activeBroadcastMessage"
                                 fill-width
                                 taggify
                                 format="TEXTAREA"
                                 :characterLimit="charLimit"
                                 @input="updateMessage($event, activeBroadcastMessage), activeBroadcastMessageLength = ($event || '').length"></basic-mad-lib-input>
          </div>
          <small class="text-muted">
            <template v-if="activeBroadcastMessageContainsFluencyTag" class="text-warning">Tag content is dynamic. Some creatives may not receive this message if the character count exceeds line length limits.</template>
            <template v-else>Fluency will broadcast this message to ads that match your rules</template>
          </small>
        </div>
        <div class="content" v-if="filteredBroadcasts.length > 0">
          <label class="mt-5 mb-0 d-inline">Edit Rule Sets</label>
          <transition-group name="list" tag="div" class="d-flex flex-column">
          <div v-for="(broadcast, broadcastIndex) in broadcastsWithActiveMessage"
               :key="broadcast.accountBroadcastId"
          class="list-item">
            <broadcast-edit :value="broadcastsWithActiveMessage[broadcastIndex]"
                            @change="updateBroadcast($event)"
                            @clone="cloneRuleSet(broadcast)"
                            @removed="handleRemoved($event)"
                            :field-mapping-values="fieldMappingValues"
                            :match-level-values="matchLevelValues"
                            :match-operator-values="matchOperatorValues"
            ></broadcast-edit>
          </div>
          </transition-group>
        </div>
      </div>
    </div>

    <broadcast-preview :show-preview="showPreview"
                       @update:show-preview="showPreview = $event"
                       :broadcasts="filteredBroadcasts"
                       @publish="publish"
                       :resizable="false"
                       :drawer-width="'calc(100% - 20px)'" />
  </component>
</template>

<script>
import BroadcastEdit from './broadcastEdit'
import BroadcastPreview from './broadcastPreview'
import _cloneDeep from 'lodash.clonedeep'
import _delay from 'lodash.delay'
import BroadcastPublishTool from './broadcastPublishTool'
import NotificationBadge from '@/components/common/notificationBadge'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'

const accountBroadcastSkeletonType = 'AccountBroadcast'
const TAG_REGEX = /\[.*?\]/g

export default {
  name: 'broadcast',
  components: { BasicMadLibInput, NotificationBadge, BroadcastPublishTool, BroadcastPreview, BroadcastEdit },
  props: {
    // Optional prop to override the default account broadcasts
    providedAccountBroadcasts: {
      type: Array,
      default: null
    },
    card: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      broadcastNameFields: [
        { key: 'delete', label: ' ', editable: false },
        { key: 'creativeMessage', label: 'Creative Message', editable: true },
        { key: 'view', label: ' ', editable: false }
      ],
      activeBroadcastMessageLength: 0,
      activeBroadcastMessage: null,
      temporaryBroadcastIdCounter: -1,
      showPreview: false,
      unsavedBroadcastAlertId: 'broadcast-unsaved',
      customerBroadcasts: [],
      accountBroadcastDraftsForCustomer: [],
      accountBroadcasts: [],
      expandedMetaList: false,
      broadcastMeta: [],
      lineLengthValidation: { // check for line length for various items
        HEADLINE_1: 30,
        HEADLINE_2: 30,
        HEADLINE_3: 30,
        DESCRIPTION: 90,
        DESCRIPTION_2: 90,
        PATH_1: 15,
        PATH_2: 15
      },
      fieldMappingEnums: null,
      matchLevelEnums: null,
      matchOperatorEnums: null,
      selectedBroadcastIds: [],
      specialBroadcastTagsCollapse: false
    }
  },

  watch: { // deep-analyzed
    broadcasts: {
      handler: function (val) {
        if (!this.broadcastLookupByMessage[this.activeBroadcastMessage]) {
          // reset activeBroadcastMessage to null when all broadcasts for that message are removed
          this.setActiveBroadcastMessage(null)
        }
        if (val && val.length > 0 && this.activeBroadcastMessage === null && val[val.length - 1].creativeMessage !== null) {
          // if activeBroadcastMessage is null, set it to the first one in the last
          this.setActiveBroadcastMessage(val[0].creativeMessage)
        }
      },
      immediate: true
    },
    dirtyBroadcasts (val) {
      this.$store.commit('setAppUnsavedTools', { name: this.unsavedBroadcastAlertId, value: val.length > 0 })
    },
    '$store.getters.customerBroadcasts': {
      immediate: true,
      handler: function (val) {
        if (val) {
          const allCustomerBroadcasts = _cloneDeep(val)
          this.$setCompat(this, 'customerBroadcasts', allCustomerBroadcasts.filter(cb => !cb.accountPlanId))
        } else {
          this.customerBroadcasts = []
        }
      }
    },
    '$store.getters.broadcasts': {
      immediate: true,
      handler: function (val) {
        // Only update if no prop is provided
        if (!this.providedAccountBroadcasts) {
          if (val) {
            this.$setCompat(this, 'accountBroadcasts', _cloneDeep(val))
          } else {
            this.accountBroadcasts = []
          }
        }
      }
    },
    providedAccountBroadcasts: {
      immediate: true,
      handler: function (val) {
        if (val !== null) {
          // If prop is provided, use it directly
          this.$setCompat(this, 'accountBroadcasts', _cloneDeep(val))
        } else {
          // If prop is null, fall back to store broadcasts
          const storeVal = this.$store.getters.broadcasts
          this.$setCompat(this, 'accountBroadcasts', storeVal ? _cloneDeep(storeVal) : [])
        }
      }
    },
    '$store.state.data.accountBroadcastDraftsForCustomer': {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.$setCompat(this, 'accountBroadcastDraftsForCustomer', _cloneDeep(val))
        } else {
          this.accountBroadcastDraftsForCustomer = []
        }
      }
    },
    'account.accountPlanId': {
      immediate: true,
      handler: async function (val, oldVal) {
        if (val && val !== oldVal) {
          const broadcastMeta = await this.$res.fetch.broadcastMeta({ accountPlanId: val })
          if (broadcastMeta) {
            this.broadcastMeta.splice(0, this.broadcastMeta.length, ...broadcastMeta)
          } else {
            this.broadcastMeta.splice(0, this.broadcastMeta.length)
          }
        }
      }
    }
  },
  computed: {
    isCustomerContext () {
      return this.$route.name.includes('customer')
    },
    isAccountContext () {
      return this.$route.name.includes('manage') || this.providedAccountBroadcasts
    },
    account () {
      if (this.providedAccountBroadcasts) {
        return { // build the account object for use
          accountPlanId: this.providedAccountBroadcasts[0].accountPlanId,
          name: null
        }
      } else {
        return this.$store.getters.settingsItem
      }
    },
    broadcasts () {
      if (this.isAccountContext) {
        return this.accountBroadcasts
      } else if (this.isCustomerContext) {
        return this.customerBroadcasts
      }
      return []
    },
    contextBroadcastLabel () {
      if (this.isAccountContext) {
        return 'Account Broadcast Messages'
      } else if (this.isCustomerContext) {
        return 'Global Broadcast Messages'
      }
      return ''
    },
    otherContextBroadcastMessages () {
      if (this.isAccountContext) {
        return (this.customerBroadcasts || [])
          .filter(broadcast => !broadcast.removed)
          .map(broadcast => broadcast.creativeMessage)
          .distinct()
      } else {
        return (this.accountBroadcasts || [])
      }
    },
    hasBroadcasts () {
      return this.filteredBroadcasts.length > 0
    },
    filteredBroadcasts () {
      return Object.values(this.broadcasts || [])
        .filter(broadcast => !broadcast.removed)
    },
    uniqueBroadcastMessages () {
      return Object.keys(this.broadcastLookupByMessage).map(msg => msg === 'null' ? null : msg)
    },
    broadcastLookupByMessage () {
      const lookup = this.filteredBroadcasts.reduce((lookup, broadcast) => {
        if (lookup[broadcast.creativeMessage]) {
          lookup[broadcast.creativeMessage].push(broadcast)
        } else {
          lookup[broadcast.creativeMessage] = [broadcast]
        }
        return lookup
      }, {})
      return lookup
    },
    broadcastsWithActiveMessage () {
      return this.broadcastLookupByMessage[this.activeBroadcastMessage] || []
    },
    charLimit () {
      // calculates the lowest char limit between the broadcasts in this.broadcastsWithActiveMessage
      const limit = this.broadcastsWithActiveMessage.reduce((accumulator, current) => (this.lineLengthValidation[current.fieldMapping] || 999) < accumulator ? this.lineLengthValidation[current.fieldMapping] : accumulator, 999)
      return limit === 999 ? undefined : limit
    },
    saveButtonVariant () {
      if (this.invalidBroadcasts.length > 0) {
        return 'ghost-danger'
      } else if (this.dirtyBroadcasts.length > 0) {
        return 'ghost-warning'
      } else {
        return 'ghost-secondary'
      }
    },
    buttonBarDisabled () {
      return this.broadcasts.length === 0 ||
        !this.activeBroadcastMessage ||
        this.activeBroadcastMessage.length === 0 ||
        this.invalidBroadcasts.length > 0
    },
    dirtyBroadcasts () {
      return this.filteredBroadcasts.filter(b => b.dirty)
    },
    hasDrafts () {
      const drafts = this.filteredBroadcasts.filter(b => b.status === 'DRAFT')
      return drafts.length > 0
    },
    invalidBroadcasts () {
      return this.filteredBroadcasts.filter(b => b.invalid && b.invalid.length > 0)
    },
    fieldMappingValues () {
      return this.fieldMappingEnums || undefined
    },
    matchLevelValues () {
      return this.matchLevelEnums || undefined
    },
    matchOperatorValues () {
      return this.matchOperatorEnums || undefined
    },
    activeBroadcastMessageContainsFluencyTag () {
      return TAG_REGEX.test(this.activeBroadcastMessage)
    }
  },
  methods: {
    // Select all broadcast messages
    selectAll () {
      // Use the indices of uniqueBroadcastMessages to select all broadcasts
      this.selectedBroadcastIds = this.uniqueBroadcastMessages.map((_, index) => index)

      // Trigger the onBroadcastSelect method to emit the selected broadcasts
      this.onBroadcastSelect()
    },

    // Deselect all broadcast messages
    selectNone () {
      // Clear the selectedBroadcastIds array
      this.selectedBroadcastIds = []

      // Trigger the onBroadcastSelect method to emit the selected broadcasts (which will be none)
      this.onBroadcastSelect()
    },
    handleRemoved (broadcast) {
      this.selectNone()
      this.setActiveBroadcastMessage(this.uniqueBroadcastMessages[0])
    },
    onBroadcastSelect () {
      // Get the selected broadcasts based on the selectedBroadcastIds
      const selectedBroadcasts = this.selectedBroadcastIds.map(index => {
        const message = this.uniqueBroadcastMessages[index]
        return this.broadcastLookupByMessage[message]
      }).flat() // Flatten the array of arrays

      // Emit the selected event with the selected broadcasts
      this.$emit('selected', selectedBroadcasts)
    },
    removeSelectedBroadcasts () {
      // Map through the uniqueBroadcastMessages using the selectedBroadcastIds
      this.selectedBroadcastIds.forEach(index => {
        const messageToRemove = this.uniqueBroadcastMessages[index]

        // Find all broadcasts with this creative message and mark them as removed
        this.filteredBroadcasts
          .filter(bc => bc.creativeMessage === messageToRemove)
          .forEach(bc => {
            bc.removed = true
          })
      })

      // Clear the selected broadcast ids
      this.selectedBroadcastIds = []

      // If there are remaining broadcasts, set the active message to the first unique message
      if (this.hasBroadcasts) {
        this.setActiveBroadcastMessage(this.uniqueBroadcastMessages[0])
      } else {
        this.setActiveBroadcastMessage(null)
      }
    },
    async saveBroadcasts () {
      await this.$store.dispatch('saveBroadcasts', this.broadcasts)
    },
    async addNewMessage () {
      const createdBroadcast = this.createBroadcast(null)

      const broadcastsArray = Object.values(this.broadcasts)
      broadcastsArray.push(createdBroadcast)
      this.setBroadcasts(broadcastsArray)
      this.setActiveBroadcastMessage(null)
    },
    cloneActiveMessage  () {
      // let createdBroadcast = this.createBroadcast(null)
      // if unique messages contains 'this.activeBroadcastMessage + ' (cloned)' then message = this.activeBroadcastMessage + ' ' + timestamp
      let cloneMessage = this.activeBroadcastMessage + ' (cloned)'
      if (this.uniqueBroadcastMessages.includes(cloneMessage)) {
        cloneMessage = this.activeBroadcastMessage + ' (cloned) ' + this.$moment().format('MM-DD h:mm:ss a ')
      }
      const clonedCasts = this.broadcastsWithActiveMessage
        .map(existing => {
          const createdBroadcast = {
            ...this.createBroadcast(cloneMessage),
            accountBroadcastMatchers: _cloneDeep(existing.accountBroadcastMatchers || [])
              .map(newMatcher => ({
                ...newMatcher,
                accountBroadcastId: null,
                accountBroadcastMatcherId: null
              })),
            fieldMapping: existing.fieldMapping,
            validFrom: existing.validFrom,
            validTo: existing.validTo
          }
          return createdBroadcast
        })

      const array = Object.values(this.broadcasts)
      array.push(...clonedCasts)
      this.setBroadcasts(array)
      this.setActiveBroadcastMessage(cloneMessage)
    },
    async deleteBroadcastGroup (row) {
      this.filteredBroadcasts
        .filter(bc => bc.creativeMessage === row.item.creativeMessage)
        .forEach(bc => {
          bc.removed = true
          return bc
        })

      if (this.hasBroadcasts) {
        const jumpToIndex = row.index === 0 ? 0 : row.index - 1
        this.setActiveBroadcastMessage(this.uniqueBroadcastMessages[jumpToIndex])
      }
    },
    cloneRuleSet (fromRuleSet) {
      if (!fromRuleSet) {
        return
      }
      const createdBroadcast = {
        ...this.createBroadcast(this.activeBroadcastMessage),
        fieldMapping: fromRuleSet.fieldMapping,
        validFrom: fromRuleSet.validFrom,
        validTo: fromRuleSet.validTo,
        accountBroadcastMatchers: _cloneDeep(fromRuleSet.accountBroadcastMatchers || [])
          .map(newMatcher => ({
            ...newMatcher,
            accountBroadcastId: null,
            accountBroadcastMatcherId: null
          }))
      }
      const broadcastsArray = [...this.broadcasts]
      broadcastsArray.push(createdBroadcast)
      this.setBroadcasts(broadcastsArray)
      this.setActiveBroadcastMessage(this.activeBroadcastMessage)
    },
    addBroadcast () {
      const createdBroadcast = this.createBroadcast(this.activeBroadcastMessage)
      const broadcastsArray = [...this.broadcasts]
      broadcastsArray.push(createdBroadcast)
      this.setBroadcasts(broadcastsArray)
      this.setActiveBroadcastMessage(this.activeBroadcastMessage)
    },

    setActiveBroadcastMessage (message) {
      this.$setCompat(this, 'activeBroadcastMessage', message)
      this.activeBroadcastMessageLength = message ? message.length : 0
    },

    async updateMessage (newMessage, oldMessage) {
      if (newMessage !== oldMessage) {
        this.filteredBroadcasts
          .filter(bc => bc.creativeMessage === oldMessage)
          .forEach(bc => {
            bc.creativeMessage = newMessage
            bc.status = 'DRAFT'
            this.$setCompat(bc, 'dirty', true)
            return bc
          })
        this.setBroadcasts(this.broadcasts)
        this.setActiveBroadcastMessage(newMessage)
      }
    },
    // helpers
    updateBroadcast (broadcast) {
      let index = this.broadcasts.indexOf(broadcast)
      if (index < 0) {
        index = this.broadcasts.findIndex(b => b.accountBroadcastId === broadcast.accountBroadcastId)
      }
      if (index > -1) {
        const broadcasts = [...this.broadcasts]
        broadcasts[index] = broadcast
        broadcasts[index].dirty = true
        this.setBroadcasts(broadcasts)
      }
    },
    setBroadcasts (broadcasts) {
      if (this.providedAccountBroadcasts) {
        this.$emit('update', [...broadcasts])
      } else if (this.isAccountContext) {
        this.$store.commit('broadcasts', [...broadcasts])
      } else if (this.isCustomerContext) {
        this.$store.commit('customerBroadcasts', [...broadcasts])
      }
    },
    createBroadcast (message) {
      const newBroadcast = this.$store.getters.newSkeleton(accountBroadcastSkeletonType)
      newBroadcast.accountBroadcastMatchers = [{
        accountBroadcastId: null,
        matchLevel: this.matchLevelValues[0],
        matchOperator: this.matchOperatorValues[0]
      }]
      newBroadcast.accountPlanId = this.isAccountContext ? this.account.accountPlanId : null

      newBroadcast.creativeMessage = message
      newBroadcast.fieldMapping = this.fieldMappingValues ? this.fieldMappingValues[0] : 'HEADLINE_1'
      newBroadcast.removed = false
      newBroadcast.status = 'DRAFT'
      newBroadcast.accountBroadcastId = this.temporaryBroadcastIdCounter
      newBroadcast.dirty = true
      newBroadcast.invalid = []

      if (this.broadcastsWithActiveMessage?.length > 0) {
        const lastBroadcast = this.broadcastsWithActiveMessage[this.broadcastsWithActiveMessage.length - 1]
        newBroadcast.validFrom = lastBroadcast.validFrom
        newBroadcast.validTo = lastBroadcast.validTo
      }

      this.temporaryBroadcastIdCounter = this.temporaryBroadcastIdCounter - 1

      return newBroadcast
    },
    async publish (broadcasts = []) {
      if (this.hasDrafts) {
        if (this.isAccountContext) {
          await this.$res.set.publishBroadcasts(this.filteredBroadcasts[0]) // pass in just the first broadcast, and the publish function will destructure the accountPlanId
          this.$toast('Broadcasts Published!', 'success')
          await this.$store.dispatch('fetchBroadcasts', this.filteredBroadcasts[0])
        } else if (this.isCustomerContext) {
          if (broadcasts?.length > 0) {
            // specific broadcasts set from argument, so publish by ids
            const payload = broadcasts.filter(broadcast => broadcast.accountBroadcastId)
              .map(broadcast => broadcast.accountBroadcastId)
              .distinct()
            if (payload.length > 0) {
              await this.$res.set.publishBroadcastsByIds(payload)
              this.$toast(`${payload.length} Draft${payload.length > 1 ? 's' : ''} Published`, 'success')
              await this.$store.dispatch('fetchBroadcasts', { accountPlanId: null, fetchAccountDraftsForCustomer: true })
            }
          } else {
            await this.$res.set.publishCustomerBroadcasts()
            this.$toast('Global Broadcasts Published!', 'success')
            await this.$store.dispatch('fetchBroadcasts', { accountPlanId: null })
          }
        }
      }
    },
    async openPreview () {
      _delay(async () => {
        await this.saveBroadcasts()
        this.showPreview = true
      }, 100)
    },
    getStatusBadgeVariantForMessage (message) {
      const broadcasts = this.broadcastLookupByMessage[message]
      if (broadcasts.some(b => b.invalid && b.invalid.length > 0)) {
        return 'danger'
      } else if (broadcasts.some(b => b.dirty)) {
        return 'warn'
      } else if (broadcasts.some(b => b.status === 'DRAFT')) {
        return 'contrast'
      } else if (broadcasts.some(b => b.status === 'Expired')) {
        return 'contrast'
      } else {
        return 'primary'
      }
    },
    getTagForMessage (message) {
      const broadcasts = this.broadcastLookupByMessage[message]
      if (broadcasts.some(b => b.invalid && b.invalid.length > 0)) {
        return 'INVALID'
      } else if (broadcasts.some(b => b.dirty)) {
        return 'UNSAVED'
      } else if (broadcasts.some(b => b.status === 'DRAFT')) {
        return 'DRAFT'
      } else if (broadcasts.some(b => b.status === 'Expired')) {
        return 'EXPIRED'
      } else {
        return 'PUBLISHED'
      }
    },
    // Set enums - either grab it from the store if it exists, or fetch them and set in store.
    async setEnums () {
      await this.fetchAndSetEnum('AccountBroadcastFieldMapping', 'fieldMappingEnums')
      await this.fetchAndSetEnum('AccountBroadcastMatcherMatchLevel', 'matchLevelEnums')
      await this.fetchAndSetEnum('AccountBroadcastMatcherMatchOperator', 'matchOperatorEnums')
    },
    async fetchAndSetEnum (enumPath, propertyName) {
      if (!this.$store.getters.enumerations[enumPath]) {
        await this.$store.dispatch('fetchEnumeration', `${enumPath}`)
      }
      this[propertyName] = this.$store.getters.enumerations[enumPath]
    }
  },
  mounted () {
    this.setEnums()
  }
}
</script>

<style lang="scss" scoped>
  .list-item {
    transition: all 250ms;
  }

  .list-enter, .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  .list-leave-active {
    position: absolute;
  }

  .list-move {
    transition: transform 250ms;
  }

  .creative-message.active, .creative-message:hover {
    background-color: $bionic-surface-100;
    color: $fluency-gray;
    border-color: var(--p-primary-color);
  }

  .hover-container {
    .hover-icon {
      visibility: hidden;
    }
    &:hover {
      cursor: pointer;
      color: var(--p-primary-color);
      .hover-icon {
        visibility: visible;
      }
    }
  }

  .meta-list-icon {
    transition: all 250ms;
    transform: rotate(0deg);
  }
  .expanded-meta-list {
    transform: rotate(405deg);
  }
  .broadcast-container {
    container-type: inline-size;
  }
  .broadcast-main-content {
    margin-left: 1em;
  }

  @container (max-width: 650px) {
    .broadcast-flex-container {
      flex-direction: column !important;
    }

    .broadcast-main-content {
      /* display: none !important;*/
      margin-left: 0;
    }
  }
</style>
